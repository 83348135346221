.mdb-dataTable-head {
  background-color: $focusMenuColor !important;
  > tr > th > label {
    @extend .subheading;
  }
}

.mdb-dataTable {
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  overflow: hidden;
  margin-top: 3%;

  > tbody[data-test="table-body"] > tr > td {
    @extend .caption;
  }
}

.dt-bootstrap {
  padding: 0;
}

.mdb-datatable-filter {
  width: 40%;
}

.mdb-datatable-filter > input {
  @extend .input-group-container__no-icon;
  @extend .body-content;
  width: 100% !important;
}

.mdb-datatable-info > div {
  @extend .caption;
}

.dt-list-items {
  thead {
    background-color: #e6e9ef;
    height: 4rem;
    border-collapse: separate;
    border-spacing: 0 !important;
    tr {
      text-align: center;
      align-items: center;
      th {
        text-align: justify;
        color: $primaryColorDefault;
        font-size: 1.5rem;
      }
      th:first-child {
        box-shadow: 4px 4px 25px 3px rgb(0 0 0 / 5%);
        border-radius: 1.4rem 0 0 0;
      }
      th:last-child {
        box-shadow: 4px 4px 25px 3px rgb(0 0 0 / 5%);
        border-radius: 0 1.4rem 0 0;
      }
    }
  }
  tbody {
    tr {
      height: 4.5rem;
      td {
        max-width: 15rem;
        font-size: 1.4rem;
        color: $defaultTextColor;
        vertical-align: middle;
        padding: 9px;
      }
    }
  }
  .mdb-datatable {
    .d-flex {
      font-size: 1rem;
      color: $defaultTextColor;
    }
  }
  .custom-badge {
    color: $defaultTextColor;
  }
}

.dt-list-items-change-last-child {
  thead {
    background-color: #e6e9ef;
    height: 4rem;
    border-collapse: separate;
    border-spacing: 0 !important;
    tr {
      text-align: center;
      align-items: center;
      th {
        text-align: justify;
        color: $primaryColorDefault;
        font-size: 1.5rem;
      }
      th:first-child {
        box-shadow: 4px 4px 25px 3px rgb(0 0 0 / 5%);
        border-radius: 1.4rem 0 0 0;
      }
      th:last-child {
        box-shadow: 4px 4px 25px 3px rgb(0 0 0 / 5%);
        border-radius: 0 1.4rem 0 0;
        // width: 300px;
      }
    }
  }
  tbody {
    tr {
      height: 4.5rem;
      td {
        font-size: 1.4rem;
        color: $defaultTextColor;
        vertical-align: middle;
      }
    }
  }
  .mdb-datatable {
    .d-flex {
      font-size: 1rem;
      color: $defaultTextColor;
    }
  }
  .custom-badge {
    color: $defaultTextColor;
  }
}

.datatable-CB {
  &__col-searchbar {
    @extend .datatable-CB__col-label;
  }
  &__col-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__button-label {
    @extend .button;
    text-transform: none !important;
    background-color: $primaryColorDefault !important;
    color: $primaryBorderColorDefault !important;
    width: 153px;
    height: 36px;
  }

  &__col-sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__button-sort {
    @extend .button;
    text-transform: none !important;
    background-color: $secondaryColorDefault !important;
    color: $primaryColorDefault !important;
    width: 133px;
    height: 36px;
  }

  &__chips {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__chip-container {
      @extend .caption;
      color: $defaultTextColor !important;
      background-color: $disabledColorDefault !important;
      border-radius: 0.2rem;
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__inner-buttons {
    &__open {
      @extend .button;
      text-transform: none !important;
      color: $primaryColorDefault !important;
      background-color: $secondaryColorDefault !important;
    }

    &__select {
      @extend .button;
      text-transform: none !important;
      color: $primaryBorderColorDefault !important;
      background-color: $primaryColorDefault !important;
    }
  }

  &__tr-name {
    padding-left: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.custom-datatable {
  border-radius: 0.8rem !important;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px !important;
  margin: 1% 0% !important;

  &__container {
    overflow-y: scroll;
    height: 100%;
    height: calc(100vh - 18rem);
  }
}
