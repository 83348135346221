.sidebar-base {
  background-color: $primaryMenuColor;
  width: 17% !important;
  height: 100%;

  &__container {
    height: 100%;
  }

  &__header-row {
    height: fit-content;
    margin-bottom: 3%;
    &__header-container {
      padding-top: 7%;
      padding-left: 12% !important;
      margin-bottom: 3%;

      &__name {
        @extend .heading;
        padding-top: 3%;
      }
      &__role {
        @extend .caption;
      }
    }
  }

  &__navbar-row {
    height: 60%;

    &__navbar-container {
      padding-top: 3%;
      padding-left: 5% !important;

      &__nav-row {
        height: 3.2rem !important;
        &:hover {
          background-color: $focusMenuColor;
          border-left: 4px solid $primaryColorDefault;
        }

        &:focus {
          background-color: $focusMenuColor;
        }

        &__selected {
          @extend .sidebar-base__navbar-row__navbar-container__nav-row;
          background-color: $focusMenuColor;
          border-left: 4px solid $primaryColorDefault;
        }
      }

      &__nav-element {
        @extend .button;
        color: $primaryColorDefault;
        padding: 3% 0% 0% 0%;
      }

      &__nav-icon {
        width: 20px !important;
        height: 20px !important;
      }

      &__icon-container {
        padding-right: 0 !important;
        padding-bottom: 1.5%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__logout-row {
    margin-bottom: 7%;
    margin-left: 1% !important;
    margin-right: 1% !important;

    height: 3.2rem !important;
    @extend .button;
    color: $primaryColorDefault;

    &__button {
      display: flex !important;
      justify-content: flex-start !important;
    }
    &__icon-container {
      padding-right: 0 !important;
      padding-bottom: 1.5%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__logout-icon {
      width: 20px !important;
      height: 20px !important;
    }

    &__logout-text {
      @extend .button;
      color: $primaryColorDefault;
      text-transform: none;
    }
  }

  &__logo-row {
    @extend .display-large;
    color: $primaryColorDefault;
    font-weight: $font-weight-caption;
    padding: 10% 0%;

    &__logo-container {
      text-align: center;
    }

    &__logo-svg {
      width: 100% !important;
      height: 100% !important;
    }

    &__logo-title {
      display: flex;
      justify-content: left;
      align-items: center;
    }
  }
}
.sidebar__bottom__container {
  bottom: 0;
  position: absolute;
  width: 100%;
  justify-content: end;
  background-color: $backgroundMenusColor;
  margin-left: -15px !important;
  z-index: 1;
  padding: 10px;
}
