$primary-dark-blue: #00374f;
$color-font-body: #393b53;
$error: #e44258;
$information: #71c9c1;
$warning: #f9600b;
$success: #00ca72;
$disableText: #676879;
$text: #393b53;
$heading: #00374f;
$link: #17b4bc;
$subtitle: #323338;
$secondary: #fff;
$active: #067bbd;
$default: #00374f;
$hover: #045380;
$focus: #e7f9fc;
$miniicon: #022e47;

///

// PRIMARY COLORS ------------------------------------
$primaryColorDefault: #00374f;
$primaryColorHover: #045380;
$primaryColorActive: #067bbd;
$primaryBorderColorDefault: #ffffff;
$primaryBorderColorHover: #e6e9ef;
$primaryBorderColorActive: #e2eaed;

// SECONDARY COLORS ----------------------------------
$secondaryColorDefault: #e7f9fc;
$secondaryColorHover: #cbecee;
$secondaryColorActive: #95d0d3;

// DISABLED COLORS -----------------------------------
$disabledColorDefault: #e6e9ef;
$disabledColorBorder: #c5c7d0;

// MENU COLORS ----------------------------------------
$primaryMenuColor: #f6f6f7;
$secondaryMenuColor: #fafcfd;
$focusMenuColor: #e2eaed;

//TEXT COLORS -----------------------------------------
$disableTextColor: #676879;
$defaultTextColor: #393b53;
$subtitleTextColor: #323338;
$headingTextColor: $primaryColorDefault;
$linkTextColor: $primaryColorActive;
$otherColorDefaultDark: #ccf4e3;

//OTHER COLORS ----------------------------------------
$otherColorDefault: #17b4bc;
$otherColorHover: #16cad3;
$otherColorActive: #16cad3;

//BACKGROUND COLORS ----------------------------------------
$backgroundMenusColor: #f1f3f6;

//FEEDBACK COLORS ------------------------------------
$errorColorDark: #e44258;
$informationColorDark: #71c9c1;
$warningColorDark: #f9600b;
$successColorDark: #00ca72;
