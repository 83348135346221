.input-group-container {
  display: flex;
  border: 1px solid $primaryBorderColorHover;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:hover {
    border: 1px solid $hover;
  }

  &:focus-within {
    border: 1.5px solid $primaryColorHover;
  }

  &__error {
    @extend .input-group-container;
    border: 1px solid $error !important;
  }

  &__no-icon {
    @extend .input-group-container;
    width: 100%;
    padding: 0.375rem 0.75rem;
    &__country-list {
      @extend .input-group-container__no-icon;
      height: 50%;
      &__error {
        @extend .input-group-container__no-icon__country-list;
        border: 1px solid $error !important;
      }
    }
  }

  &__no-icon-error {
    @extend .input-group-container;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid $error !important;
  }
}

.container-icon {
  background-color: none;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
  color: $primary-dark-blue;
}

.custom-input {
  @extend .label;
  border: none;
  // height: 4.8rem;
  width: 90%;

  &__disabled {
    background-color: $disabledColorDefault;
  }

  &__button {
    @extend .button;

    &__primary-color {
      @extend .custom-input__button;
      background-color: $primary-dark-blue !important;
      text-transform: none !important;

      &__forced {
        @extend .custom-input__button__primary-color;
        width: 150px;
        height: 40px;
      }
    }

    &__secondary-color {
      @extend .custom-input__button;
      color: $color-font-body !important;
      text-transform: none !important;
      background-color: $focus !important;
      &__forced {
        @extend .custom-input__button__secondary-color;
        width: 150px;
        height: 40px;
      }
    }

    &__link {
      color: $link;
    }
    
    &__no-hover {
      &:hover {
        color: white;
      }
    }
    
    &__default-white {
      @extend .custom-input__button;
      background-color: $primaryBorderColorDefault !important;
      text-transform: none !important;
      color: $defaultTextColor !important;
      padding: 0.3rem !important;
    }

  }
  
  &__small {
    @extend .custom-input;
    height: 3rem;
    width: 100%;
  }
  &__error {
    color: $error;
    border-color: $error;
  }
}

.spinner {
  &__white-background {
    @extend .spinner;
    color: #ffffff !important;
  }
  &__blue-background {
    @extend .spinner;
    color: $primary-dark-blue !important;
  }
}

.clickeable {
  cursor: pointer;
}

.input-upload {
  opacity: 0;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0;
  left: -10000px;
  cursor: pointer;
  margin: 1rem 10rem 0 7.5rem;

  &__button {
    padding: 8px;
    background-color: $secondaryColorDefault;
    font-weight: 700;
    color: $primaryColorDefault;
    cursor: pointer;
    border-radius: 4px;
    font-size: $font-size-md;
    align-items: center;

    &__error {
      @extend .input-upload__button;
      border: 1px solid $error !important;
    }

    &__disabled {
      @extend .input-upload__button;
      background-color: $disabledColorDefault;
      color: $disableText;
    }
  }

  &__button_primary_color {
    padding: 8px;
    background-color: $primaryColorDefault;
    font-weight: 700;
    color: $primaryBorderColorDefault;
    cursor: pointer;
    border-radius: 4px;
    font-size: $font-size-md;
  }
}
