@import "../utils/colors";
.Mui-selected {
  font-size: 1.6rem !important;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: $primary-dark-blue !important;
}

.display-x-large {
  font-family: $font-family;
  font-weight: $font-weight-x-large;
  font-size: $font-size-x-large;
  line-height: $line-height-x-large;

  &__primary-color {
    @extend .display-x-large;
    color: $primaryColorDefault;
  }
}

.display-large {
  font-family: $font-family;
  font-weight: $font-weight-large;
  font-size: $font-size-large;
  line-height: $line-height-large;

  &__primary-color-hover {
    @extend .display-large;
    color: $primaryColorHover;
  }

  &__primary-color {
    @extend .display-large;
    color: $primaryColorDefault;
  }
}

.display-medium {
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: $font-size-medium;
  line-height: $line-height-medium;
}

.display-small {
  font-family: $font-family;
  font-weight: $font-weight-small;
  font-size: $font-size-small;
  line-height: $line-height-small;

  &__primary-color {
    @extend .display-large;
    color: $primaryColorDefault;
  }
}

.heading {
  font-family: $font-family;
  font-weight: $font-weight-heading;
  font-size: $font-size-heading;
  line-height: $line-height-heading;

  &__primary-color {
    @extend .heading;
    color: $primaryColorDefault;
  }

  &__success-color {
    @extend .heading;
    color: $successColorDark;
  }

  &__error-color {
    @extend .heading;
    color: $errorColorDark;
  }

  &__warning-color {
    @extend .heading;
    color: $warningColorDark;
  }
  &__subtitle {
    @extend .heading;
    color: $subtitleTextColor;
  }

  &__primary-active {
    @extend .heading;
    color: $primaryColorActive;
  }
  &__hover {
    @extend .heading;
    color: $primaryColorHover;
  }
}

.subheading {
  font-family: $font-family;
  font-weight: $font-weight-subheading;
  font-size: $font-size-subheading;
  line-height: $line-height-subheading;
}

.label {
  font-family: $font-family !important;
  font-weight: $font-weight-label !important;
  font-size: $font-size-label !important;
  line-height: $line-height-label !important;

  &__primary-color {
    @extend .label;
    color: $primaryColorDefault;

    &__page-title {
      @extend .label__primary-color;
      border-bottom: 3px solid $primaryColorDefault;
    }
  }
}

.button {
  font-family: $font-family !important;
  font-weight: $font-weight-button !important;
  font-size: $font-size-button !important;
  line-height: $line-height-button !important;
  &__primary-Color-Active {
    @extend .label;
    color: $primaryColorActive !important;
  }
}

.body-content {
  font-family: $font-family;
  font-weight: $font-weight-body;
  font-size: $font-size-body;
  line-height: $line-height-body;

  &__justify {
    @extend .body-content;
    text-align: justify;
  }

  &__text-disabled {
    @extend .body-content;
    color: $disabledColorBorder;
  }
}

.caption {
  font-family: $font-family !important;
  font-weight: $font-weight-caption !important;
  font-size: $font-size-caption !important;
  line-height: $line-height-caption !important;

  &__primary-color {
    @extend .caption;
    color: $primaryColorDefault;

    &__active {
      @extend .caption;
      color: $primaryColorActive;
    }
  }

  &__other-Color-Active {
    @extend .caption;
    color: $otherColorActive;
    cursor: pointer;
  }

  &__helper-text {
    @extend .caption;
    color: $disabledColorBorder;
  }

  &__justify {
    @extend .caption;
    text-align: justify;
  }
  &__disabled {
    @extend .caption;
    color: $disableTextColor;
  }
  &__error {
    @extend .caption;
    color: $error;
  }
}

.link {
  font-family: $font-family;
  font-weight: $font-weight-link;
  font-size: $font-size-link;
  line-height: $line-height-link;
}
