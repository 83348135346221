.btn {
  &_submit_create {
    width: 9.9rem;
    height: 4.4rem;
    background-color: #00374f;
    color: #ffffff;
    border-radius: 0.4rem;
    border: none;
    font-size: 1.4rem;
    align-items: flex-end;
  }

  &_white_background {
    background-color: #e7f9fc;
    color: #00374f;
    border-radius: 0.4rem;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    align-items: flex-end;
  }

  &_blue_background {
    background-color: #00374f;
    color: #ffffff;
    border-radius: 0.4rem;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    align-items: flex-end;
  }

  &_disable {
    background-color: $primaryBorderColorHover;
    color: $disableText;
    border-radius: 0.4rem;
    border: none;
    font-size: 1.4rem !important;
    font-weight: bold !important;
    align-items: flex-end;
    text-transform: none !important;
  }

  &_back {
    margin-left: 2rem;
    margin-right: 2rem;
    border: none;
    width: 8.1rem;
    height: 3.6rem;
    border-radius: 0.4rem;
    align-items: flex-end;
    background-color: #00374f;
    color: #ffffff;
  }

  &_icon {
    background-color: "secondary";
    border-radius: 4px;
  }

  &-icon-deny {
    width: 36px;
    height: 36px;
    border: 0px;
    color: $secondary;
    border-radius: 4px;
    background-color: $error;
  }

  &-icon-success {
    width: 36px;
    height: 36px;
    border: 0px;
    color: $secondary;
    border-radius: 4px;
    background-color: $success;
  }

  &-icon-blue {
    width: 36px;
    height: 36px;
    border: 0px;
    color: $secondary;
    border-radius: 4px;
    background-color: $primary-dark-blue;
  }
}

.tooltip-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f6f6f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #17b4bc;
  font-size: 14px;
  position: absolute;
  z-index: 1;
  text-align: center;
  bottom: 48px;
  left: -135px;
  width: 320px;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 16px;
}
.tooltip-box:before {
  content: " ";
  position: absolute;
  background: #f6f6f7;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 3px;
  width: 18px;
  height: 18px;
  z-index: 1;
  top: 2.8rem;
  left: 145px;
  transform: rotate(45deg);
  margin: 0px 0px;
}

@mixin small {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

.row_res {
  justify-content: start;
  @include small {
    justify-content: end;
  }
}

.custom-input {
  @extend .label;
  border: none;
  // height: 4.8rem;
  width: 90%;

  &__disabled {
    background-color: $disabledColorDefault;
  }

  &__button {
    @extend .button;
    width: 100%;
    box-shadow: none !important;
    border-radius: 8px !important;
    padding: 8px !important;

    // height: 4rem;

    &__primary-color {
      @extend .custom-input__button;
      background-color: $primaryColorDefault !important;
      text-transform: none !important;
      color: $primaryBorderColorDefault !important;

      &__forced {
        @extend .custom-input__button__primary-color;
        width: 150px;
        height: 40px;
      }
      &__disabled {
        @extend .custom-input__button;
        background-color: $disabledColorBorder !important;
        color: $disableTextColor !important;
        text-transform: none !important;
      }
    }

    &__disabled {
      @extend .custom-input__button;
      background-color: $disabledColorDefault;
    }

    &__secondary-color {
      @extend .custom-input__button;
      color: $defaultTextColor !important;
      text-transform: none !important;
      background-color: $secondaryColorDefault !important;
      &__forced {
        @extend .custom-input__button__secondary-color;
        width: 150px;
        height: 40px;
      }
    }
    // &__secondary-color {
    //   @extend .custom-input__button;
    //   background-color: $secondaryColorDefault !important;
    //   color: $primaryColorDefault !important;
    // }

    &__link {
      text-transform: none !important;
      color: $otherColorDefault !important;
    }

    &__no-hover {
      &:hover {
        color: white;
      }
    }
  }

  &__data-table-icon {
    width: fit-content !important;
    height: fit-content !important;

    &__disabled {
      @extend .custom-input__data-table-icon;
      color: $disabledColorDefault !important;
    }
  }

  &__small {
    @extend .custom-input;
    height: 3rem;
    width: 100%;
  }
  &__error {
    color: $errorColorDark;
    border-color: $errorColorDark;
  }
}

.confirmation {
  &__logo {
    width: 90px;
    height: 90px;
  }

  &__name {
    @extend .display-x-large;
    color: $primaryColorDefault;
    font-weight: $font-weight-caption;
    padding: 0;
  }

  &__comment {
    @extend .display-small;
    color: $primaryColorDefault;
    font-weight: $font-weight-caption;
  }
}

.drag-color {
  color: $disabledColorBorder;
}
