.home-title-row {
    @extend .display-x-large__primary-color;
    width: 100%;
    height: 12%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .home-init-cards-row {
    width: 100%;
    height: 25%;
    margin-bottom: 17px !important;
    &__title-row {
      @extend .heading__primary-color;
      height: 20%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  
    &__cards-container {
      height: 80%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  
    &__alert-title{
      @extend .heading__primary-color;
      font-weight: 900;
      font-size: 28px;
    }
  
    
  }
  
  .home-info-cards-row {
    height: 40vh;
    width: 100%;
    margin-bottom: 2%;
    &__general-row {
      padding: 0% 1% 0% 0% !important;
      &__layout {
        height: 100%;
        border-radius: 0.8rem;
        box-shadow: rgb(0 0 0 / 8%) 4px 4px 25px 3px;
        background-color: $primaryBorderColorDefault;
        &__title {
          @extend .heading__subtitle;
          padding-top: 10px !important;
          // height: 30%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 1%;
        }
        &__cards {  
          flex-direction: column;
          justify-content: center;    
        }
      }
    }
  }
  
  .home-folders {
    height: 33% !important;
    width: 100% !important;
    &__title {
      @extend .heading__primary-active;
      height: 15% !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  
    &__folders {
      display: flex;
      justify-items: center;
      margin-top: 1% !important;
      height: 46px !important;
      box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px;
  
      &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        padding-right: 0px;
      }
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px !important;
        height: 25px !important;
        padding: 0 !important;
        margin-right: 15% !important;
        margin-left: 1% !important;
      }
      &__title {
        @extend .heading__primary-color;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
  
      &__alerts-text {
        @extend .subheading;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
  
      &__to-contain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &__alerts-number {
        @extend .button;
        margin-top: 3%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px !important;
        height: 36px !important;
        background-color: $secondaryColorDefault;
        border-radius: 0.8rem;
        padding: 10%;
      }
  
      &__button {
        @extend .button;
        justify-items: flex-end;
        width: 64px !important;
        height: 36px !important;
        background-color: $primaryColorDefault !important;
        color: $primaryBorderColorDefault !important;
        text-transform: none !important;
        margin-top: 3% !important;
      }
    }
  }
  
  .home-img {
    display: flex;
    justify-content: center;
    align-items: center;
  
    &__clickable {
      @extend .home-img;
      width: 35px;
      height: 35px;
      filter: invert(100%) sepia(8%) saturate(0%) hue-rotate(50deg)
        brightness(107%) contrast(107%);
    }
  
    &__informative {
      @extend .home-img;
      width: 25px;
      height: 25px;
      filter: brightness(0) saturate(100%) invert(41%) sepia(16%);
    }
  }
  