.custom-tooltip {
  @extend .caption;
  background-color: white !important;
  color: black !important;
  border-radius: 4px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2) !important;
  text-align: center !important;
  max-width: 404px !important;
  padding: 5px !important;
}
.custom-tooltip::before {
  border-top-color: #f6f6f7 !important;
}
.custom-popover {
  z-index: 0 !important;
  border-radius: 0.4rem !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.200691);
  background-color: $primaryMenuColor;
  border: none !important;
  &__large {
    @extend .custom-popover;
    max-width: 480px !important;
    width: max-content !important;
  }
  &__xlarge {
    @extend .custom-popover;
    max-width: 600px !important;
    width: max-content !important;
  }
  &__header {
    background-color: $primaryMenuColor !important;
  }
  &__body {
    padding: 1rem !important;
  }
}
