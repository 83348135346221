.dropzone {
  width: 16rem;
  height: 17rem;
  border-radius: 17px;
  background: white;
  // padding: 1.5rem;
  position: relative;
  transition: all 300ms;

  &_input {
    opacity: 0;
    width: 9rem;
    height: 3.6rem;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    margin-left: 15rem;
  }

  &_upload {
    opacity: 0;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0;
    left: -10000px;
    cursor: pointer;
    margin: 1rem 10rem 0 7.5rem;
  }
}

.custom-accordion {
  border-radius: 0.8rem !important;
  box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px !important;
  margin: 1% 0% !important;

  &__import-numeral {
    border-radius: 0.8rem !important;
    box-shadow: rgb(0 0 0 / 5%) 4px 4px 25px 3px !important;
    margin: 0.5% 0% !important;
  }

  &__container {
    overflow-y: scroll;
    height: 100%;
    height: calc(100vh - 25rem);
  }

  &__container-blankspaces {
    overflow-y: scroll;
    height: 100%;
    height: calc(100vh - 1rem);
  }

  &__container-document {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 15rem);
  }

  &__container-headers {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 20rem);
  }

  &__container-document-folder {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 29rem);
  }

  &__container__small {
    overflow-y: scroll;
    height: 100%;
    max-height: calc(100vh - 30rem);
  }

  &__container__small-2 {
    overflow-y: scroll;
    height: 100%;
    max-height: calc(100vh - 32rem);
  }
}
