.container-style {
  width: 22.3rem;
  height: 100%;
  background-color: #f6f6f7;
  position:fixed;
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
}

.sidebar-header {
  font-family: "Roboto", sans-serif;
  margin-left: 1.6rem;
  padding-top: 2rem;
  position: relative;

  .name {
    font-size: 1.6rem;
    margin-bottom: 0rem;
    font-weight: bold;
  }
  .role {
    font-size: 1.2rem;
    font-weight: lighter;
  }
}

.sidebar-navbar {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  position: relative;

  .navbar-text {
    margin: 0rem 0rem 0rem 0.9rem;
  }
  .nav-link {
    display: flex;
    color: #202223;
    align-items: flex-start;
  }
  .nav-link:hover {
    color: #00374f;
    background-color: #e2eaed;
  }
  .nav-link:focus {
    color: #00374f;
    background-color: #e2eaed;
  }
  .icons {
    margin: 0.3rem 0rem 0rem 0.8rem;
  }
}

.sidebar-logout {
  display: flex;
  margin-top: 41.3rem;
  position: relative;

  .navbar-text {
    padding: 0.3rem 0rem 0rem 0rem;
    margin: 0rem 0rem 0rem 0rem;
  }

  .icons {
    margin: 0rem 0.7rem 0rem 0rem;
  }

  .inner-button {
    width: 30rem;
    padding: 0.7rem 0rem 0.7rem 2.2rem;

    color: #202223;
    text-transform: none;
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
  }

  .inner-button:hover {
    background-color: #e2eaed;
  }
}

.sidebar-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0.9rem 0rem 0rem 2.9rem;
  .logo-name {
    width: 15.7rem;
    height: 5.1rem;
    margin-bottom: 1.9rem;
    font-size: 2.8rem;
    font-family: "Roboto", sans-serif;
    padding-top: 1rem;
  }
  .legal-logo {
    padding: 0.5rem 0rem 0rem 0rem;
  }
}
