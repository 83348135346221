.modal-dialog .modal-content {
  border-radius: 4px !important;
}

.modal-open .modal {
  display: flex;
  justify-content: center;
}
.modal.show .modal-dialog {
  display: flex;
  justify-content: center;
}
