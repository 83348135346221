.container-blankspaces {
  padding: 0 !important;

  box-shadow: none !important;
  &__box {
    @extend .container-blankspaces;
    padding: 5% !important;
  }
  .MuiAccordionSummary-root {
    background: $secondaryMenuColor !important;
  }
  .MuiAccordionDetails-root {
    background: rgba(0, 55, 79, 0.05) !important;
  }
}
.container-blankspaces > .css-o4b71y-MuiAccordionSummary-content {
  margin: 0 !important;
}

.blank-space-text {
  font-weight: bold;
  color: $primaryColorHover;
}

.container_text {
  padding: 2%;
  &__clause-text {
    @extend .container_text;
    padding-left: 5%;
  }
  &__subclause {
    @extend .container_text;
    padding-left: 10% !important;
  }
}
.Mui-expanded {
  min-height: auto !important;
}
