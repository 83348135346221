@import "utils/colors";
@import "utils/variables";
@import "base/base";
@import "base/typography";
@import "base/searchbar";
@import "base/dataTable";
@import "base/form";
@import "layouts/userLayout";
@import "login/_login";
@import "base/buttons";
@import "base/modals";
@import "base/dropzone";
@import "base/NavigationBar";
@import "base/cards";
@import "base/inputs";
@import "base/sidebar";
@import "base/modalUploadDocs";
@import "base/home";
@import "base/blankspaces";
@import "base/tooltip";
@import "base/images";
@import "base/filterTag";
@import "base/custom-container";
.container-page {
  max-width: 100%;
  width: 100%;
  overflow: auto;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
}

.container_inside_layout {
  width: 100%;
  margin-top: 2rem;
}
