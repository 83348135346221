*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

html,
body {
  height: 100%;
}

body > #root > div {
  height: 100vh;
}

html {
  /* Root font size, defines what 1rem is */
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
}

body {
  box-sizing: border-box;
}

p {
  margin-bottom: 0 !important;
}

.align-center {
  display: flex;

  &__horizontal {
    @extend .align-center;
    justify-content: center;
  }

  &__vertical {
    @extend .align-center__horizontal;
    align-items: center;
  }
}

.loading-content {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  background-color: $primaryColorDefault;
  opacity: 0.3;

  &--spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.loading-content-linear {
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: #ffffff;
  opacity: 0.7;

  &--spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.cursor__pointer {
  cursor: pointer;
}
