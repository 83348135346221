.nav-style{
    display: flex; 
    position: fixed;
    left: 25rem; 
    padding: 2rem 0rem 0rem;
    background: #FFFFFF;
    width: 100%;
    z-index: 2;
}

.title-back{
    width: 'max-content';
    align-self: center;
    height: 20px;
    font-size: 14px;
    margin-top: 0.1rem;
    color: #C5C7D0;
    cursor: pointer;
}

.btn-arrow-back{
    width: 20px;
    height: 20px;
    border: 0;
    background-color: #E7F9FC;
    border-radius: 4px;
    margin-right: 1rem;
}

.nav-footer{
    display: flex;
    position: fixed;
    justify-content: flex-end;
    width: 80%;
    padding: 1rem;
    background: #FFFFFF;
    z-index: 2;
    bottom: 0;
}

.tabContextBottom{
    margin-bottom: 7rem !important;
}