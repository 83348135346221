.searchBar {
  width: 55rem;
  height: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 0.5rem;
  border-color: rgb(228, 228, 228);
  background: url("https://static.thenounproject.com/png/101791-200.png")
    no-repeat right;
  background-size: 2rem;
  border: 0.1rem solid #c5c7d0;
}
input[class="searchBar"]::placeholder {
  padding-left: 0.5rem;
  font-size: 1.4rem;
}
